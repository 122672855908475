.dark {
	/* --background-color: #282c34; */
	--background-color: black;
	/* --background-color: rgb(32, 33, 36); */
	--text-color: #ffffff;
	/* --button-background: #444; */
	--button-background: black;
	/* --button-background: rgb(32, 33, 36); */
	--button-text-color: #ffffff;
	--grey: #9c9c9c;
  /* --text-box: rgb(48, 49, 52); */
  --text-box: rgb(80, 80, 80);
  --link: lightblue;
  --hover: lightblue;
  }


.text-area {
  color: var(--button-text-color); /* Add this line to change the input text color */
}

.text-area::placeholder {
  color: var(--grey);
}
  
.search-button {
	color: var(--button-text-color);
	border: 1px solid var(--button-text-color);
  }
