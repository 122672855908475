:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --button-background: #ffffff;
  --button-text-color: #000000;
  --button-border-color: #000000;
  --grey: #717171;
  --text-box: rgb(240, 240, 240);
  --hover: rgb(147, 147, 147);
  /* --hover: rgb(104, 195, 226); */
  --link: blue;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  text-align: center;
}

h4 {
  max-width: 245px; 
  margin: 20px auto;
  margin-top: 30px;
  text-align: justify;
  font-weight: bold;
}

.h4center {
  max-width: 245px; 
  margin: 20px auto;
  margin-top: 30px;
  text-align: center;
  font-weight: bold;
}

h5 {
  max-width: 245px;
  margin: 20px auto;
  text-align: center;
  margin-top: 30px;
  text-align: center;
  font-weight: bold;
}

.create label {
  text-align: center;
  font-size: 500;
  color: green;
}

.create input, .create textarea, .create select {
  text-align: center;
  font-size: 900;
  color: green;
}

.search-container {
  display: flex;
  align-items: center;
}

.text-area {
  width: 290px;
  height: 23px;
  font-size: 16px;
  border-radius: 7px;
  resize: none;
  background-color: var(--background-color);
  font-family: Arial, sans-serif;
  font-weight: bold;
  border: 1px solid var(--button-text-color);
  padding-left: 0.5em;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body.focused {
  background-color: var(--text-box);
}

.text-area::placeholder {
  color: var(--grey);
}

.search-button {
  height: 30px;
  font-size: 16px;
  border: none;
  background-color: var(--button-background);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  border-radius: 7px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

/* .text-area:hover {
  border-color: var(--hover);
} */

/* .search-button:hover,
.size-input:hover {
  border-color: var(--hover);
  color: var(--hover);
} */

@media not all and (pointer: coarse),
  not all and (-webkit-touch-enabled: true) {
  /* Styles for non-iOS and non-Android devices on hover */
  .search-button:hover,
  .size-input:hover {
    border-color: var(--hover);
    color: var(--hover);
  }
}

.text-area:focus::placeholder {
  color: var(--button-text-color);
}

.text-area:focus {
  background-color: var(--text-box);
  outline: none;
}

a:link {
  color: var(--link);
  }
  
a:visited {
  color: var(--link);
  }


/* Hide scrollbar on all elements */
 ::-webkit-scrollbar {
  display: none;
}

.octo-octo-logo {
  position: fixed;
  bottom: 5%; /* 6% */
  left: 50%;
  transform: translateX(-50%);
  width: 30px; /* 60px */ 
  height: 30px; /* 60px */ 
  z-index: 9999;
}

.octo-octo-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid black;
  transition: opacity 0.3s ease;
}
